import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import AlserkalAdvisoryWrapper from "../../components/AlserkalAdvisoryWrapper";
import { getStartDate, cleanCraftDate } from "../../modules/time";
import styled from "styled-components";
import {
  OurStoryBottom,
  OurStoryTop,
} from "../../components/OurStoryStyles/elements";
import emailjs from 'emailjs-com';
import { GlobalStyle } from "../../globalStyles";
import CloseButton from "../../components/icons/CloseButton";
import { SmallButton, BigButton } from "../../components/Buttons";
import { FoundationFrontText } from "../../components/FoundationStyles/elements";
const ContactUsAdvisoryWrapper = styled.div`
 
  width: 35%;
  position:relative;
  background-color: rgba(255, 255, 255, 0.95);
  padding-top: 50px;
  padding-bottom: 0px;
  margin-bottom: 5px;
  position: fixed; /* Stay in place */
  z-index: 999999999999 !important; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  & svg {
    float: right;
  }
  & h3{
    color: #ff644e;
    margin: 0px 0px 20px;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.32px;
    font-weight: 600;
    text-align: left;
  }
  & div{
    margin: auto;
    padding: 25px;
    border: 1px solid #888;
    width: 80%;
    background-color: #fefefe;
    max-height: 80vh;
    overflow-y: scroll;
    max-width: 460px;
  }
  & form{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    background-color: #fefefe;
   
    & p {
      display: flex;
      margin-bottom:15px;
      justify-content: space-between;
      & > span {
        display: inline-block;
        min-width: 5px;
      }
    }
    & label {
      display: flex;
      flex-direction: column;
      width: 100%;
      color: var(--text);
      font-size: 15px;
      line-height: 1.4;
      letter-spacing: 0.3px;
      & input,
      & textarea {
        border: 1px solid #000000;
        box-sizing: border-box;
        height: 36px;
        padding: 8px;
        margin-top: 4.5px;
        line-height: 1.4;
        letter-spacing: 0.3px;
        width: 100%;
        font-family: var(--headerFont);
        font-size: 15px;
        color: var(--black);
      }
      & textarea {
        height: 144px;
      }
    }
    & input[type="submit"],
    & button {
      color: #ff644e;
      border: 1px solid #ff644e;
      width: 200px;
      height: 36px;
      font-size: 15px;
      font-family: var(--headerFont);
      line-height: 21px;
      font-weight: 500;
      margin-top: 35px;
      margin-bottom: 10px;
      background-color: var(--white);
      @media (max-width: 500px) {
        width: 100%;
      }
    }
  }
  @media (max-width: 767px) {
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 1px;
    & > form {
      margin-bottom: 0;
      & > div {
        padding: 0 !important;
      }
    }
  }
`;
const ButtonOuterDiv = styled.div`
    margin: auto;
    width: 50%;
    padding: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    @media (max-width: 767px) {
      width: max-content;
      justify-items: center;
      align-items: center;
      text-align: center;
      margin: inherit;
  }
  
}
`;
const PerspectivesDiv = styled.div`
--topMargin: "50px";
--bottomMargin: ${(props) =>
    props.bottomMargin ? props.bottomMargin : "30px"};
--tileWidth: 400px;
--textColor: var(--white);
--iconOpacity: 0;
transition: 0.5s;
display: flex;
flex-direction: column;
max-width: var(--width);
margin-bottom: var(--bottomMargin);
margin-top: var(--topMargin);
margin-left: 0px;
margin-right: -20px;
padding-top: ${(props) =>
    props.aboveBanner ? "25px" : 0}; /* note that this was 100 for front page*/
& h3 {
  margin-bottom: 20px;
  z-index: 2;
}
& > div {
  display: flex;
  flex-wrap: wrap;
  & > div {
    width: var(--tileWidth);
    height: 230px;
    margin-right: 20px;
    margin-bottom: 20px;
    position: relative;
    &:after {
      position: absolute;
      bottom: 0;
      height: 3px;
      left: 0;
      right: 0;
      content: "";
      background-color: var(--green);
      opacity: var(--iconOpacity);
      z-index: 2;
      transition: 0.5s;
    }
    &:hover {
      --borderColor: var(--green);
      --textColor: var(--green);
      --iconOpacity: 1;
    }
    & > a {
      width: 100%;
      height: 100%;
      display: inline-block;
    }
    & img,
    & .gatsby-image-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: var(--tileWidth) !important;
      height: 230px !important;
      z-index: 1;
      object-fit: cover;
    }
    & div {
      pointer-events: none;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.5) 100%
      );
      user-select: none;
      position: absolute;
      padding-bottom: 20px;
      padding-right: 60px;
      padding-left: 20px;
      left: 0;
      bottom: 0;
      right: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      color: var(--textColor);
      z-index: 2;
      transition: 0.5s;
      & a {
        color: var(--textColor);
        transition: 0.5s;
      }
      & h6 {
        margin-top: 5px;
      }
      & svg {
        position: absolute;
        right: 20px;
        bottom: 20px;
        transition: 1s;
        opacity: var(--iconOpacity);
      }
    }
  }
}
/* @media (min-width: 1441px) { */
position: relative;
&:before {
  background-color: var(--white);
  content: "";
  position: absolute;
  left: -2000px;
  right: -2000px;
  top: calc(0px - var(--topMargin));
  bottom: calc(0px - var(--bottomMargin));
  top: -75px; //why?
  /* } */
}
@media (max-width: 1023px) {
  & > div {
    justify-content: center;
  }
}
@media (max-width: 767px) {
  --tileWidth: 375px;
  --topMargin: 25px;
  --bottomMargin: 0;
  /* max-width: var(--tileWidth); */
  margin-right: 0;
  align-items: center;
  & h3 {
    margin-left: 25px;
  }
  & h3.alserkal-title
  {
    margin-left: 0px;
    font-size: 30px;
  }
  & > div {
    max-width: var(--tileWidth);
    justify-content: flex-start;
    & > div {
      margin-right: 0;
      margin-bottom: 25px;
    }
  }
}
  
  
`;
import ProgrammeGrid from "../../components/UpcomingProgrammes";
const allTimes = () => true;

const upcomingProgrammesAlserkalAdvisoryPage = () => {

  const [showPopup, SetShowPopup] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_b63obcc', 'template_ew3a1ue', e.target, '1oiFBKwf2u2uXZh0F')
      .then((result) => {
        SetShowPopup(false)
        //SetShowModal(prev => !prev)
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset();
  }
  const upcomingProgrammes = useStaticQuery(graphql`
    query {
        craftUpcomingProgrammesAlserkalAdvisoryUpcomingProgrammesAlserkalAdvisoryEntry {
            richTextField
            upcomingProgrammesListing{
                ... on Craft_event_event_Entry {
                    id
                    title
                    slug
                    eventEndDateTime
                    eventStartDateTime
                    eventShortDescription
                    eventLocation {
                        title
                        slug
                        typeHandle
                    }
                    listingImage {
                        url
                        ... on Craft_communityAssets_Asset {
                        card: localFile {
                            publicURL
                            childImageSharp {
                            fixed(height: 190, width: 190, fit: COVER) {
                                src
                                ...GatsbyImageSharpFixed
                            }
                            }
                        }
                        }
                    }
                    headerImage {
                        url
                        ... on Craft_editorialAssets_Asset {
                          card: localFile {
                            publicURL
                            childImageSharp {
                              fixed(height: 190, width: 190, fit: COVER) {
                                src
                                ...GatsbyImageSharpFixed
                              }
                            }
                          }
                        }
                      }
                }
            }
        }
    }
  `).craftUpcomingProgrammesAlserkalAdvisoryUpcomingProgrammesAlserkalAdvisoryEntry;

  const now = new Date();
  const everything = upcomingProgrammes.upcomingProgrammesListing.filter((x) => {
    const thisDate = new Date(x.eventEndDateTime || x.eventStartDateTime);
    //return thisDate >= now;
    return true;
  });
  const [shownEvents /*setShownEvents*/] = React.useState(everything); // Do we need this?
  const [currentTimeFilter, setCurrentTimeFilter] = React.useState(
    () => allTimes
  );
  const [currentTimeFilterName, setCurrentTimeFilterName] =
    React.useState(null);
  const [startDate, setStartDate] = React.useState(getStartDate("today")); // this is a real date


  return (
    <AlserkalAdvisoryWrapper
      useStayInformed={true}
      selected={true}
      selection={2}
      title={
        <React.Fragment>
          Upcoming
          <br /> Programmes
        </React.Fragment>
      }
    >
      <PerspectivesDiv style={{ backgroundColor: 'var(--white)', padding: '0px 100px 0px' }}>
        <ul style={{ zIndex: '99999999' }}>

          <section>
            <div></div>
            {upcomingProgrammes.richTextField && upcomingProgrammes.richTextField.length > 0 ? (
              <FoundationFrontText
                dangerouslySetInnerHTML={{ __html: upcomingProgrammes.richTextField }}
                style={{ paddingBottom: "20px" }}
              />
            ) : (
              null
            )}



          </section>

        </ul>

      </PerspectivesDiv>

      <PerspectivesDiv style={{ backgroundColor: 'var(--white)', padding: '0px 100px 0px' }} >
        {shownEvents
          .filter((x) =>
            currentTimeFilter(
              cleanCraftDate(x.eventStartDateTime),
              cleanCraftDate(x.eventEndDateTime || x.eventStartDateTime)
            )
          ).length ? (

          <ul style={{ zIndex: '99999999' }}>
            <ProgrammeGrid
              events={shownEvents
                .filter((x) =>
                  currentTimeFilter(
                    cleanCraftDate(x.eventStartDateTime),
                    cleanCraftDate(x.eventEndDateTime || x.eventStartDateTime)
                  )
                )}
              firstDate={startDate}
              firstDateType={currentTimeFilterName}
            />


          </ul>


        ) : (
          <div>No programmes match your selections!</div>
        )}
        <ButtonOuterDiv>
          <BigButton
            color={"var(--purple)"}
            style={{ zIndex: 1 }}
            // onClick={(e) => {
            //   e.preventDefault();
            //   navigate(`/contact-us`);
            // }}
            onClick={() => {
              SetShowPopup(true);
            }}
          >
            {"Get in touch"}
          </BigButton>
        </ButtonOuterDiv>
      </PerspectivesDiv>
      <OurStoryBottom>
        {showPopup
          ?
          <ContactUsAdvisoryWrapper>
            <div>

              <CloseButton
                color="var(--red)"
                onClick={() => {
                  SetShowPopup(false);
                }}
              />
              <h3>Get In Touch</h3>
              <form onSubmit={sendEmail} >
                <p>
                  <label>
                    <input type="hidden" name="from_name" value="info@alserkal.online"></input>
                    First name<input type="text" name="First_Name" required></input>
                  </label>
                  <span>&nbsp;</span>
                  <label>
                    Last name<input type="text" name="Last_name" required></input>
                  </label>
                </p>
                <p>
                  <label>
                    Email address<input type="email" name="Email_address" required></input>
                  </label>
                </p>
                <p>
                  <label>
                    Mobile number<input type="text" name="Mobile_number" required></input>
                  </label>
                </p>
                <p>
                  <label>
                    Company name
                    <input type="text" name="Company_name" required></input>
                  </label>
                </p>
                <p>
                  <label>
                    Job title
                    <input type="text" name="Job_title" required></input>
                  </label>
                </p>
                <p>
                  <label>
                    We’re curious to know more…
                    <textarea name="Message" required />
                  </label>
                </p>
                <input type="submit" value="Submit" />

              </form>
            </div>
          </ContactUsAdvisoryWrapper>
          : null
        }


        <GlobalStyle />



      </OurStoryBottom>
    </AlserkalAdvisoryWrapper>
  );
};

export default upcomingProgrammesAlserkalAdvisoryPage;
